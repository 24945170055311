import React from "react"
import styled from "styled-components"

import Seo from "../../components/seo"
import PageWrapper from "../../styles/PageWrapper"
import Row from "../../styles/Row"

const TermsConditionsPageWrapper = styled(PageWrapper)`
  padding-top: 4rem;

  color: ${props => props.theme.color.primary};
`

const AgreementButton = styled.a`
  display: inline-block;
  padding: 1rem 2rem;
  background-color: ${props => props.theme.color.primary};
  color: ${props => props.theme.color.whiteBg};
  border-radius: 2rem;
  font-weight: bold;
`

export default function TermsConditionsPage() {
  return (
    <TermsConditionsPageWrapper>
      <Seo title="Terms and Conditions" />
      <Row>
        <h2>Terms and Conditions of Sale</h2>

        <p>
          1. <strong>Agreement and Acceptance</strong>. These terms and
          conditions of sale ("Terms") govern the sale of the products specified
          in any sales order, purchase order, acknowledgment, invoice, or other
          order form ("Products") by f'Real! Foods, LLC ("f'real") to the buyer
          named thereon ("Buyer"). Buyer and f'real may be referred to
          individually as a "Party" and collectively as the "Parties." F'REAL'S
          DELIVERY OF PRODUCTS TO BUYER AND BUYER'S ACCEPTANCE OF THE DELIVERY
          OF PRODUCTS SHALL CONSTITUTE ACCEPTANCE OF THESE TERMS BY THE PARTIES.
          THE PARTIES ACKNOWLEDGE AND AGREE THAT THE SALE OF PRODUCTS BY F'REAL
          TO BUYER SHALL NOT BE GOVERNED BY ANY TERMS OR CONDITIONS SET FORTH ON
          BUYER'S ORDER OR ANY OTHER AGREEMENT. THESE TERMS, INCLUDING THE
          SOFTWARE LICENSE AGREEMENT ATTACHED HERETO AND INCORPORATED HEREIN BY
          THIS REFERENCE, CONSTITUTE THE ENTIRE AGREEMENT BETWEEN THE PARTIES
          REGARDING THE SUBJECT MATTER CONTAINED HEREIN, AND SUPERSEDE ANY AND
          ALL PRIOR OR CONTEMPORANEOUS WRITTEN OR ORAL COMMUNICATIONS,
          UNDERSTANDINGS, AND AGREEMENTS OF THE PARTIES. NO ADDITIONS OR
          MODIFICATIONS OF THESE TERMS SHALL BE EFFECTIVE UNLESS MADE IN WRITING
          AND SIGNED BY AN AUTHORIZED REPRESENTATIVE OF EACH PARTY.
        </p>

        <p>
          2. <strong>Orders</strong>. Buyer may, from time to time, submit to
          f'real an order for Products (each, an "Order"). Each Order shall, at
          a minimum, specify the quantity of each type of Product, the proposed
          delivery date(s), the proposed delivery site(s) ("Delivery Site"), and
          shipping instructions. f'real may, within seven (7) business days
          after receiving an Order, accept the Order by providing to Buyer a
          written sales order confirmation ("Sales Order Confirmation") via
          email. If an Order has not been accepted by f'real after seven (7)
          business days, the Order shall be deemed rejected. Buyer acknowledges
          and agrees that upon acceptance of an Order, Buyer shall be bound to
          purchase the quantity of Products specified in the Order. F'REAL'S
          ACCEPTANCE OF AN ORDER IS EXPRESSLY CONDITIONED ON THE BUYER'S
          ACCEPTANCE OF THESE TERMS.
        </p>

        <p>
          3. <strong>Shipping; Title; Risk of Loss</strong>. f'real shall use
          reasonable efforts to deliver Products to the Delivery Site(s) by the
          estimated delivery date set forth in the Sales Order Confirmation
          using f'real's standard methods for packaging and shipping (except as
          otherwise agreed to in writing by the Parties). Buyer acknowledged
          that such estimated delivery date is only an estimate and agrees that
          f'real shall not be liable for any delay in the delivery of Products.
          f'real may, without liability or penalty, make partial shipments of
          Products to Buyer. Each shipment will constitute a separate sale, and
          Buyer shall pay for the units of Products shipped whether such
          shipment is in whole or partial fulfillment of Buyer's Order. Buyer
          acknowledges and agrees that Buyer shall be required to take delivery
          of all Products when such Products are delivered. Except as otherwise
          agreed to in writing by the Parties, Products shall be delivered CIP
          Delivery Site (Incoterms® 2020). Title to Products and risk of loss
          shall transfer to Buyer immediately upon delivery of the Products to
          the first carrier.
        </p>

        <p>
          4. <strong>Acceptance and Nonconforming Products</strong>. Products
          shall be deemed accepted by Buyer unless Buyer provides f'real with
          written notice of its rejection of Products within two (2) days after
          delivery to the Delivery Site. Buyer may only reject Products that are
          defective, damaged, or that do not conform to the specifications
          ("Nonconforming Products") and shall furnish to f'real written
          evidence of the same. If Buyer timely notifies f'real of Nonconforming
          Products, f'real shall, in its sole discretion, either (i) repair the
          Nonconforming Products; (ii) replace the Nonconforming Products; or
          (iii) in lieu of repairing or replacing the Nonconforming Products,
          credit or refund the price for such Nonconforming Products, less any
          applicable discounts or rebates. If f'real exercises its option under
          (ii) or (iii) above, then Buyer shall ship the Nonconforming Products
          to the location designated by f'real and f'real shall, after receiving
          the Nonconforming Products, ship the replacement Products to Buyer at
          the Delivery Site specified on the Order. BUYER ACKNOWLEDGES AND
          AGREES THAT THE REMEDIES SET FORTH IN THIS SECTION 4 ARE BUYER'S
          EXCLUSIVE REMEDIES FOR THE DELIVERY OF NONCONFORMING PRODUCTS. Except
          as provided in Section 4 or Section 8, Buyer has no right to return
          the Products.
        </p>

        <p>
          5. <strong>Prices and Taxes</strong>. The price of Product shall be
          f'real's price in effect for Products at the time of the submission of
          the Order as confirmed in the Sales Order Confirmation. f'real
          specifically rejects any price of Products specified by Buyer on any
          Order or on any other order form submitted by Buyer to f'real. Except
          for certain import charges applicable to certain Delivery Site(s), all
          prices are exclusive of all sales, use, and excise taxes, and other
          taxes, duties, and charges of any kind imposed by any governmental
          authority on any amounts payable by Buyer. Buyer shall be responsible
          for all such charges, costs, and taxes.
        </p>

        <p>
          6. <strong>Payment Terms</strong>. f'real shall provide an invoice to
          Buyer upon shipment of Products. All invoices are due and payable in
          accordance with the payment terms set forth on the Sales Order
          Confirmation. Buyer shall be liable for and shall reimburse f'real for
          f'real's actual costs and expenses incurred in connection with the
          collection of any amounts owed to f'real or enforcement of f'real's
          rights, including, without limitation, attorney's fees, court costs,
          and disbursements.
        </p>

        <p>
          7. <strong>No Setoff</strong>. Buyer shall not, and waives any right
          to, offset any amounts owed to f'real against any other amount owed to
          Buyer by f'real.
        </p>

        <p>
          8. <strong>Warranties; Remedies</strong>. f'real warrants to Buyer
          that Products shall be free from significant defects in material and
          workmanship for the earlier of either one (1) year from the date the
          Products are installed or thirteen (13) months from the date on which
          the Products are shipped. BUYER ACKNOWLEDGES AND AGREES THAT, EXCEPT
          AS EXPRESSLY SET FORTH IN THIS SECTION 8, ALL PRODUCTS ARE PURCHASED
          AND SOLD "AS IS" AND "WITH ALL FAULTS," AND F'REAL MAKES NO OTHER
          REPRESENTATION OR WARRANTY WHATSOEVER REGARDING THE PRODUCTS,
          INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, OR
          NON-INFRINGEMENT, WHETHER EXPRESS OR IMPLIED BY LAW, COURSE OF
          DEALING, COURSE OF PERFORMANCE, USAGE OF TRADE, OR OTHERWISE. THE
          WARRANTIES IN THIS SECTION 8 ARE EXCLUSIVE AND ARE IN LIEU OF ALL
          OTHER WARRANTIES WITH RESPECT TO THE PRODUCTS, ALL OF WHICH ARE
          EXPRESSLY DISCLAIMED. Buyer shall not be liable for a warranty breach
          unless Buyer gives f'real written notice of such breach within five
          (5) days after Buyer's discovery of the breach, and allows f'real an
          opportunity to examine the Products to verify the alleged breach.
          f'real shall not be liable for a warranty breach if it arises because
          Buyer failed to follow any manual, label, warning, instruction, or
          industry standard as to the storage or use of the Products, or if
          Buyer alters the Products without f'real's written authorization.
          Subject to the foregoing, in the event of a warranty breach, f'real
          shall, at f'real's sole discretion, (i) repair the affected Products;
          (ii) replace the affected Products; or (iii) in lieu of repairing or
          replacing the affected Products, credit or refund the price for such
          affected Products, less any applicable discounts or rebates. If f'real
          exercises its option under (ii) or (iii) above, then Buyer shall ship
          the affected Products to the location designated by f'real and f'real
          shall, after receiving the affected Products, ship the replacement
          Products to Buyer at the Delivery Site specified on the Order or issue
          the applicable credit or refund, as applicable. THE REMEDIES SET FORTH
          IN THIS SECTION 8 SHALL BE BUYER'S SOLE AND EXCLUSIVE REMEDIES AND
          F'REAL'S ENTIRE LIABILITY FOR ANY BREACH OF THE LIMITED WARRANTIES SET
          FORTH IN THIS SECTION 8. Except as provided in Section 4 or Section 8,
          Buyer has no right to return the Products.
        </p>

        <p>
          9. <strong>Limitation of Liability</strong>. IN NO EVENT SHALL F'REAL
          BE LIABLE FOR ANY CONSEQUENTIAL, INDIRECT, INCIDENTAL, SPECIAL,
          EXEMPLARY, PUNITIVE, OR ENHANCED DAMAGES, LOST PROFITS OR REVENUES,
          LOSS OF OPPORTUNITY, LOSS OF GOODWILL, OR DIMINUTION OF VALUE, ARISING
          OUT OF, RELATING TO, RESULTING FROM, OR IN CONNECTION WITH ANY BREACH
          OF THESE TERMS OR AN ORDER, REGARDLESS OF: (i) WHETHER SUCH DAMAGES
          WERE FORESEEABLE, (ii) WHETHER OR NOT F'REAL WAS ADVISED OF THE
          POSSIBILITY OF SUCH DAMAGES, (iii) THE LEGAL OR EQUITABLE THEORY
          (CONTRACT, TORT, OR OTHERWISE) UPON WHICH THE CLAIM IS BASED, AND (iv)
          THE FAILURE OF ANY AGREED OR OTHER REMEDY OF ITS ESSENTIAL PURPOSE. IN
          NO EVENT SHALL F'REAL'S AGGREGATE LIABILITY ARISING OUT OF, RELATING
          TO, RESULTING FROM, OR IN CONNECTION WITH THE THESE TERMS OR AN ORDER,
          WHETHER FOR BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE) OR
          OTHERWISE, EXCEED THE TOTAL AMOUNT PAID FOR THE PRODUCTS SOLD UNDER
          THE ORDER UPON WHICH THE ALLEGED CLAIM AROSE.
        </p>

        <p>
          10. <strong>Indemnification; Insurance</strong>. To the fullest extent
          permitted by law, Buyer shall indemnify, defend, and hold f'real, its
          affiliates, and their respective officers, directors, employees, and
          agents (each, an "Indemnified Party"), harmless from and against any
          and all claims, damages, fines, penalties, costs, expenses, losses,
          demands, and liabilities of any kind whatsoever (including, without
          limitation, attorney's fees, court costs, and disbursements) directly
          or indirectly arising out of, related to, resulting from, or in
          connection with, in whole or in part, Buyer's use, storage, or other
          handling of the Products and all injury, illness, or death to a
          person, or damage to property, actual or alleged, directly or
          indirectly arising out of or related thereto. Buyer shall purchase and
          maintain, at Buyer's sole cost, such insurance written in the kinds
          and minimum limits of liability as will protect f'real from any losses
          or claims which may arise out of or relate to these Terms or the
          transactions contemplated hereby.
        </p>

        <p>
          11. <strong>Compliance with Laws</strong>. Buyer shall comply with all
          laws, rules and regulations, and government orders and ordinances that
          are in any way related to Products.
        </p>

        <p>
          12. <strong>Force Majeure</strong>. Any delay or failure of f'real to
          perform any of its obligations under these Terms or an Order will be
          excused if such failure or delay is the result of a cause beyond
          f'real's reasonable control including, without limitation, the
          following events (the "Force Majeure Events"): (i) acts of God, flood,
          fire, earthquake, blizzard; (ii) explosion, war, invasion, hostilities
          (whether or not war is declared), terrorist threats (including,
          without limitation, general threats) or acts, riot, other civil
          unrest; (iii) government order or action, eminent domain, condemnation
          of property, changes in laws or regulations; (iv) organized labor
          activities or disturbances such as strikes; (v) shortages of power,
          supplies, infrastructure, or transportation; (vi) emergencies (whether
          global, national, regional, or local); and (vii) pandemic, epidemic,
          quarantines, and their related consequences. f'real shall, as soon as
          reasonably practicable, give notice of the Force Majeure Event to
          Buyer stating the period of time the Force Majeure Event is expected
          to last.
        </p>

        <p>
          13. <strong>Confidentiality</strong>. All pricing, sourcing,
          information, manufacturing processes, designs, formulas, data, or
          other technical information of f'real or any of its affiliates
          relating to Products or f'real's services related to Products shall
          remain f'real's or its affiliates' confidential information and
          property, and Buyer shall not have any rights thereto, nor any rights
          to disclose such items or information to any third party. Nothing in
          these Terms or an Order shall be construed (by implication, estoppel
          or otherwise) as granting, or as an undertaking by f'real to
          subsequently grant, to Buyer any license, right, title or interest in
          or to any present or future patent, patent application, know-how,
          copyright, trademark, trade secret or other proprietary right.
        </p>

        <p>
          14. <strong>Software and Cellular Modem</strong>. This Section 14
          applies only to Products that contain the Software (as defined below)
          and/or the Cellular Modem (as defined below). Buyer acknowledges that
          certain software, firmware, data files, and documentation are embedded
          or installed in the Products (the "Software") and that Buyer use of
          the Software is governed by the Software License Agreement attached to
          these Terms as Exhibit A. Buyer further acknowledges that a cellular
          modem is embedded or installed in the Products (the "Cellular Modem").
          F'REAL IS, AND SHALL AT ALL TIMES REMAIN, THE SOLE AND EXCLUSIVE OWNER
          OF THE CELLULAR MODEM. The Cellular Modem enables f'real to remotely
          connect to the Products to deliver Updates to the Software and to
          provide the maintenance and support services in accordance with the
          Software License Agreement. F'REAL may make modifications to or
          replace the Cellular Modem at any time upon notice to Buyer. Buyer
          shall not modify, disable, or remove the Cellular Modem from the
          Product as doing so would prevent f'real from delivering Updates to
          the Software and from providing the maintenance and support services.
          If Buyer ceases use of the Product, Buyer shall provide written notice
          to f'real thereof and, if requested by f'real, shall return the
          Cellular Modem to f'real in accordance with f'real's instructions.
        </p>

        <p>
          15. <strong>Miscellaneous</strong>. f'real and Buyer are acting
          hereunder as independent contractors. These Terms and Orders are not
          assignable by Buyer. Any assignment made in contravention of this
          Section 15 shall be null and void. The waiver by either Party of any
          of its rights or remedies or of any breach by the other Party under
          these Terms in a particular instance shall not be considered as a
          waiver of the same or different rights, remedies, or breaches in
          subsequent instances. No amendment or waiver of any provision of these
          Terms shall be effective unless made in writing and signed by both
          Parties. These Terms shall be interpreted and enforced exclusively
          under the laws of the State of New York, exclusive of any conflict of
          laws principles, and the Parties shall exercise any right or remedy
          thereunder exclusively in, and hereby consent to the exclusive
          jurisdiction of, the courts in Erie County, New York. All of f'real's
          rights and remedies provided in the Terms are cumulative and not
          exclusive, and the exercise by f'real of any right or remedy does not
          preclude the exercise of any other rights or remedies. The Parties
          expressly exclude the application of the United Nations Convention on
          Contracts for the International Sale of Goods, if and to the extent
          applicable. If any provision in these Terms is found to be invalid,
          illegal, or unenforceable, then the remainder of these Terms shall not
          be affected, and shall remain in full force and effect. The provisions
          in these Terms which by their nature should survive termination or
          expiration of these Terms shall survive and remain in full force and
          effect.
        </p>

        <h2>Exhibit A</h2>

        <h3>Software License Agreement</h3>
        <p>
          1. <strong>Agreement and Acceptance</strong>. This software license
          agreement ("Agreement") is part of the Terms and Conditions of Sale
          (the "Terms") to which it is attached and governs the license of the
          Software by f'real to Buyer. Capitalized terms used in this Agreement,
          but not defined in this Agreement, shall have the meaning provided in
          the Terms. F'REAL PROVIDES THE SOFTWARE SOLELY ON THE TERMS AND
          CONDITIONS SET FORTH IN THIS AGREEMENT AND ON THE CONDITION THAT BUYER
          ACCEPTS AND COMPLIES WITH THEM. BY ACTIVATING OR USING THE SOFTWARE,
          BUYER ACCEPTS THIS AGREEMENT AND AGREES THAT BUYER IS LEGALLY BOUND BY
          ITS TERMS. IF BUYER DOES NOT AGREE TO THE TERMS OF THIS AGREEMENT,
          BUYER ACKNOWLEDGES AND AGREES THAT BUYER DOES NOT, AND WILL NOT, HAVE
          A LICENSE TO THE SOFTWARE.
        </p>
        <p>
          2. <strong>License Grant and Scope</strong>. Subject to and
          conditioned upon Buyer's compliance with the Terms and this Agreement,
          f'real hereby grants to Buyer a non-exclusive, non-transferable,
          non-sublicensable, license to use the software, firmware, data files
          that are embedded or installed in the Products, in machine readable,
          object code form, and to access and use the f'real remote gateway
          (collectively, the "Software"), solely in connection with the
          operation of the Products and in strict compliance with the relevant
          operator manuals, which Buyer agrees to carefully review, and this
          Agreement. Buyer shall not assign, sublicense, transfer, pledge,
          lease, rent, or share Buyer's rights under this Agreement. If Buyer
          desires to sell or otherwise transfer ownership of the Products, Buyer
          must provide written notice of the proposed sale or transfer to f'real
          and any purchaser or transferee must agree to be bound by the terms
          and conditions of this Agreement.
        </p>
        <p>
          3. <strong>Use Restrictions</strong>. Buyer shall not, directly or
          indirectly, (i) use the Software beyond scope of the license granted
          under Section 2; (ii) provide any third party with access to or use of
          the Software; (iii) modify, translate, adapt, or otherwise create
          derivative works or improvements, whether or not patentable, of the
          Software or any part thereof; (iv) combine the Software of any party
          thereof with, or incorporate the Software or any party thereof in, any
          other program; (v) reverse engineer, disassemble, decompile, decode,
          or otherwise attempt to derive or gain access to the source code of
          the Software or any part thereof; (vi) remove, delete, alter, or
          obscure any trademarks or any copyright, trademark, patent, or other
          intellectual property or proprietary rights notice provided on or with
          the Software; (vii) copy the Software, in whole or in part; (viii)
          rent, lease, lend, sell, sublicense, assign, distribute, publish,
          transfer, or otherwise make available the Software, or any features or
          functionality of the Software, to any third party for any reason,
          whether or not over a network or on a hosted basis, including in
          connection with the internet or any web hosting, wide area network
          (WAN), virtual private network (VPN), virtualization, time-sharing,
          service bureau, software as a service, cloud, or other technology or
          service; (ix) use the Software in violation of any law, rule,
          regulation, or order; (x) use the Software for purposes of competitive
          analysis of the Software, the development of a competing product or
          service, or for any other purpose that is to f'real's commercial
          disadvantage; or (xi) remove, disable, circumvent, or workaround (or
          attempt to) any security measure that protects the Software.
        </p>
        <p>
          4. <strong>Maintenance and Support</strong>. Buyer shall be entitled
          to maintenance and support services for the Software which may include
          updates, upgrades, bug fixes, patches, and other error corrections
          (collectively, "Updates") as f'real makes generally available free of
          charge to all licensees of the Software then-entitled to maintenance
          and support services. Updates shall be provided at f'real's sole
          discretion. Buyer acknowledges and agrees that f'real has no
          obligation to develop any Updates. Updates will be deemed Software and
          shall be subject to the terms and conditions of this Agreement. If any
          Update requires an internet connection, then such internet connection
          shall be Buyer's sole responsibility. Maintenance and support services
          do not include any new version or new release of the Software that
          f'real may issue as a separate or new product, and f'real may
          determine whether any issuance qualifies as a new version, new
          release, or Update in its sole discretion.
        </p>
        <p>
          5. <strong>Collection and Use of Information</strong>. Buyer
          acknowledges that f'real may, directly or indirectly through the
          services of third parties, collect and store information regarding the
          use of the Software and about the Products on which the Software is
          installed or through which it otherwise is accessed and used. f'real
          may use such information for any purpose related to any use of the
          Software or the Products including, without limitation, improving
          performance of the Software, developing Updates, and improving the
          Products.
        </p>
        <p>
          6. <strong>Intellectual Property</strong>. Buyer acknowledges and
          agrees that the Software is provided under license, and not sold, to
          Buyer. Buyer does not acquire any ownership interest in the Software
          under this Agreement, or any other rights thereto, other than to use
          the same in accordance with the license granted and subject to all
          terms, conditions, and restrictions under this Agreement. f'real
          reserves and shall retain its entire right, title, and interest in and
          to the Software and all intellectual property rights arising out of or
          relating to the Software, except as expressly granted to the Buyer in
          this Agreement. Buyer shall safeguard all Software from infringement,
          misappropriation, theft, misuse, or unauthorized access. Buyer shall
          promptly notify f'real if Buyer becomes aware of any infringement of
          f'real's intellectual property rights in the Software and fully
          cooperate with f'real in any legal action taken by f'real to enforce
          its intellectual property rights.
        </p>
        <p>
          7. <strong>Term and Termination</strong>. This Agreement and the
          license granted hereunder shall remain in effect until terminated as
          set forth herein. f'real may terminate this Agreement and the license
          granted hereunder, effective upon notice to Buyer, if Buyer breaches
          this Agreement or if Buyer ceases using the Product on which it is
          embedded or installed. Upon termination, Buyer shall no longer be
          permitted to use the Software and f'real shall have the right to
          remove or destroy all copies of the Software on the Products.
        </p>
        <p>
          8. <strong>Warranty Disclaimer</strong>. THE SOFTWARE AND
          DOCUMENTATION ARE PROVIDED TO BUYER "AS IS" AND WITH ALL FAULTS AND
          DEFECTS WITHOUT WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED
          UNDER APPLICABLE LAW, F'REAL EXPRESSLY DISCLAIMS ALL WARRANTIES,
          WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, WITH RESPECT TO THE
          SOFTWARE, INCLUDING, WITHOUT LIMITATION, ALL IMPLIED WARRANTIES OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND
          NON-INFRINGEMENT, AND WARRANTIES THAT MAY ARISE OUT OF COURSE OF
          DEALING, COURSE OF PERFORMANCE, USAGE, OR TRADE PRACTICE. WITHOUT
          LIMITATION TO THE FOREGOING, THE LICENSOR PROVIDES NO WARRANTY OR
          UNDERTAKING, AND MAKES NO REPRESENTATION OF ANY KIND THAT THE SOFTWARE
          WILL MEET BUYER'S REQUIREMENTS, ACHIEVE ANY INTENDED RESULTS, BE
          COMPATIBLE, OR WORK WITH ANY OTHER SOFTWARE, APPLICATIONS, SYSTEMS, OR
          SERVICES, OPERATE WITHOUT INTERRUPTION, MEET ANY PERFORMANCE OR
          RELIABILITY STANDARDS OR BE ERROR FREE, OR THAT ANY ERRORS OR DEFECTS
          CAN OR WILL BE CORRECTED.
        </p>
        <p>
          9. <strong>Limitation of Liability</strong>. TO THE FULLEST EXTENT
          PERMITTED BY LAW, IN NO EVENT WILL F'REAL BE LIABLE TO BUYER OR ANY
          THIRD PARTY FOR ANY USE, INTERRUPTION, DELAY, OR INABILITY TO USE THE
          SOFTWARE; LOST REVENUES OR PROFITS; DELAYS, INTERRUPTION, OR LOSS OF
          SERVICES, BUSINESS, OR GOODWILL; LOSS OR CORRUPTION OF DATA; LOSS
          RESULTING FROM SYSTEM OR SYSTEM SERVICE FAILURE, MALFUNCTION, OR
          SHUTDOWN; FAILURE TO ACCURATELY TRANSFER, READ, OR TRANSMIT
          INFORMATION; FAILURE TO UPDATE OR PROVIDE CORRECT INFORMATION; SYSTEM
          INCOMPATIBILITY OR PROVISION OF INCORRECT COMPATIBILITY INFORMATION;
          OR BREACHES IN SYSTEM SECURITY; OR FOR ANY CONSEQUENTIAL, INCIDENTAL,
          INDIRECT, EXEMPLARY, SPECIAL, OR PUNITIVE DAMAGES, WHETHER ARISING OUT
          OF OR IN CONNECTION WITH THIS AGREEMENT, BREACH OF CONTRACT, TORT
          (INCLUDING NEGLIGENCE), OR OTHERWISE, REGARDLESS OF WHETHER SUCH
          DAMAGES WERE FORESEEABLE AND WHETHER OR NOT THE LICENSOR WAS ADVISED
          OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT WILL F'REAL'S
          AGGREGATE LIABILITY UNDER OR IN CONNECTION WITH THIS AGREEMENT, UNDER
          ANY LEGAL OR EQUITABLE THEORY, INCLUDING BREACH OF CONTRACT, TORT
          (INCLUDING NEGLIGENCE), STRICT LIABILITY, AND OTHERWISE, EXCEED THE
          TOTAL AMOUNT PAID F'REAL FOR THE PRODUCT ON WHICH THE SOFTWARE THAT IS
          THE SUBJECT OF THE CLAIM IS INSTALLED. THE LIMITATIONS SET FORTH IN
          THIS SECTION 9 SHALL APPLY EVEN IF BUYER'S REMEDIES UNDER THIS
          AGREEMENT FAIL OF THEIR ESSENTIAL PURPOSE.
        </p>
        <p>
          10. <strong>Compliance with Laws</strong>. Buyer shall use the
          Software in accordance with all applicable laws of the United States
          and any other applicable laws including, without limitation, those
          related to export control and trade control. Buyer acknowledges and
          agrees that the Software may be subject to export or other foreign
          trade laws restricting resale or transfer to other parties.
        </p>
        <p>
          11. <strong>Force Majeure</strong>. Any delay or failure of f'real to
          perform any of its obligations under this Agreement will be excused if
          such failure or delay is the result of a cause beyond f'real's
          reasonable control including, without limitation, the following events
          (the "Force Majeure Events"): (a) acts of God, flood, fire,
          earthquake, blizzard; (b) explosion, war, invasion, hostilities
          (whether or not war is declared), terrorist threats (including,
          without limitation, general threats) or acts, riot, other civil
          unrest; (c) government order or action, eminent domain, condemnation
          of property, changes in laws or regulations; (d) organized labor
          activities or disturbances such as strikes; (e) shortages of power,
          supplies, infrastructure, or transportation; (f) emergencies (whether
          global, national, regional, or local); and (g) pandemic, epidemic,
          quarantines, and their related consequences. f'real shall, as soon as
          reasonably practicable, give notice of the Force Majeure Event to
          Buyer stating the period of time the Force Majeure Event is expected
          to last.
        </p>
        <p>
          12. <strong>Confidentiality</strong>. All pricing, sourcing,
          information, manufacturing processes, designs, formulas, data, or
          other technical information of f'real or any of its affiliates
          relating to the Software shall remain f'real's or its affiliates'
          confidential information and property, and Buyer shall not have any
          rights thereto, nor any rights to disclose such items or information
          to any third party. Except as expressly set forth in this Agreement,
          nothing in this Agreement shall be construed (by implication, estoppel
          or otherwise) as granting, or as an undertaking by f'real to
          subsequently grant, to Buyer any license, right, title or interest in
          or to any present or future patent, patent application, know-how,
          copyright, trademark, trade secret or other proprietary right.
        </p>
        <p>
          13. <strong>Miscellaneous</strong>. f'real and Buyer are acting
          hereunder as independent contractors. This Agreement is not assignable
          by Buyer. Any assignment made in contravention of this Section 13
          shall be null and void. The waiver by either Party of any of its
          rights or remedies or of any breach by the other Party under this
          Agreement in a particular instance shall not be considered as a waiver
          of the same or different rights, remedies, or breaches in subsequent
          instances. No amendment or waiver of any provision of these Terms
          shall be effective unless made in writing and signed by both Parties.
          This Agreement shall be interpreted and enforced exclusively under the
          laws of the State of New York, exclusive of any conflict of laws
          principles, and the Parties shall exercise any right or remedy
          thereunder exclusively in, and hereby consent to the exclusive
          jurisdiction of, the courts in Erie County, New York. All of f'real's
          rights and remedies provided in this Agreement are cumulative and not
          exclusive, and the exercise by f'real of any right or remedy does not
          preclude the exercise of any other rights or remedies. The Parties
          expressly exclude the application of the United Nations Convention on
          Contracts for the International Sale of Goods, if and to the extent
          applicable. If any provision in this Agreement is found to be invalid,
          illegal, or unenforceable, then the remainder of this Agreement shall
          not be affected, and shall remain in full force and effect. The
          provisions in this Agreement which by their nature should survive
          termination or expiration of this Agreement shall survive and remain
          in full force and effect. This Agreement (and the Terms) constitutes
          the entire agreement between the Parties regarding the subject matter
          contained herein, and supersedes any and all prior or contemporaneous
          written or oral communications, understandings, and agreements of the
          Parties related to the subject matter contained herein.
        </p>

        <p>
          <AgreementButton
            href="/assets/pdf/freal-Standard-Sales-Order-Terms-and-Conditions.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Download Agreement
          </AgreementButton>
        </p>
      </Row>
    </TermsConditionsPageWrapper>
  )
}
